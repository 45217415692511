import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ipRestrictionGuard } from './services/ip-restriction.guard';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    // canActivate: [ipRestrictionGuard],
    loadChildren: () =>
      import('./main/main.module').then((mod) => mod.MainModule),
  },
  { path: 'access-denied', component: AccessDeniedComponent },
  // { path: '**', redirectTo: '' }
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
