<main class="backImg">
  <section class="event_section">
    <div class="">
      <app-header> </app-header>
    </div>
    <!-- <div class="container align-items-center"> -->
    <!-- <div class="row justify-content-center text-center">
      <img
        src="../../../assets/404 error/404-error.png"
        alt="Venue"
        loading="lazy"
        class="error-img"
      />
      <div class="text-container">
        <p class="error-text">404 Page Not Found</p>
      </div>
      <a href="#" class="error-button">Go to Home</a>
    </div> -->
    <!-- </div> -->
    <div class="container">
      <div class="row justify-content-center">
        <div class="text-center">
          <img
            src="../../../assets/404 error/404-error.png"
            alt="Venue"
            loading="lazy"
            class="error-img"
          />
          <h2 class="error-text" style="background: #fb6f10">
            404 Page Not Found
          </h2>
          <a routerLink="/" class="error-button">Back to Home page</a>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between accent-container">
      <img
        src="../../../assets/bg-image/image-1.png"
        alt="Venue"
        loading="lazy"
        class="img-responsive"
      />
      <img
        src="../../../assets/bg-image/image-2.png"
        alt="Musician"
        loading="lazy"
        class="img-responsive"
      />
    </div>
  </section>

  <div class="sideblur"></div>
</main>

<section class="main_footer">
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="footer_content">
            <div class="footer_item">
              <a routerLink="/">
                <img
                  class="logo"
                  src="../../../../assets/images/hamara-venue.webp"
                  alt="Hamara Venue"
                  title="Hamara Venue"
                  loading="lazy"
                  width="100%"
                  height="100%"
              /></a>
              <p class="description">
                Whether you're a fan of dreamy white weddings or care-free
                bohemian celebrations, you'll find tips, tricks, and inspiration
                to guide you through every.
              </p>
              <div class="social_links">
                <a
                  href="https://www.facebook.com/profile.php?id=61559971631842"
                >
                  <img
                    src="../../../assets/icons/facebook.png"
                    alt="facebook"
                    title="facebook"
                    loading="lazy"
                /></a>
                <!-- <a href="facebook.com"> <img src="../../../assets/icons/whatsapp.png" alt="whatsapp"
                                        title="whatsapp" loading="lazy" /></a> -->
                <a href="https://www.instagram.com/hamara.venue/">
                  <img
                    src="../../../assets/icons/instagram.png"
                    alt="instagram"
                    title="instagram"
                    loading="lazy"
                /></a>
                <a href="https://x.com/HamaraVenuee">
                  <img
                    src="../../../assets/icons/x-logo.png"
                    alt="x-logo"
                    title="x-logo"
                    loading="lazy"
                    href="facebook.com"
                /></a>
                <a href="https://www.youtube.com/@HamaraVenue">
                  <img
                    src="../../../assets/icons/youtube.png"
                    alt="youtube"
                    title="youtube"
                    loading="lazy"
                /></a>
                <a href="https://www.tiktok.com/@hamaravenue">
                  <img
                    src="../../../assets/icons/tiktok.png"
                    alt="tiktok"
                    title="tiktok"
                    loading="lazy"
                /></a>
                <a href="https://www.pinterest.com/hamaravenue/">
                  <img
                    src="../../../assets/icons/pinterest.png"
                    alt="pinterest"
                    title="pinterest"
                    loading="lazy"
                /></a>
              </div>
            </div>

            <div class="footer-links footer_item">
              <h5>Product</h5>
              <ul>
                <li><a routerLink="/">Features</a></li>
                <li><a routerLink="/">Solutions</a></li>
                <li><a routerLink="/pricing">Pricing</a></li>
                <li><a routerLink="/">Tutorials</a></li>
                <li><a routerLink="/">Updates</a></li>
              </ul>
            </div>
            <div class="footer-links footer_item">
              <h5>Remaining</h5>
              <ul>
                <li><a routerLink="/blog">Blog</a></li>
                <li>
                  <a routerLink="/terms-and-conditions">Terms & Conditions</a>
                </li>
                <li><a routerLink="/contact-us">Contact Us</a></li>
                <li><a routerLink="/about-us">About Us</a></li>
                <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
              </ul>
            </div>
            <div class="footer-links footer_item">
              <h5>My Accounts</h5>
              <ul>
                <li><a routerLink="/user-profile">My Account</a></li>
                <li><a routerLink="/order-history">Order History</a></li>
                <li><a routerLink="/invoices">Shoping Cart</a></li>
                <li><a routerLink="/faq">FAQ's</a></li>
              </ul>
            </div>

            <div class="footer_newsletter footer_item">
              <h5>SIGN UP FOR OUR NEWSLETTER</h5>
              <p class="para">
                Receive our latest updates about our products and promotions.
              </p>
              <form action="" method="post">
                <input
                  type="email"
                  name="email"
                  [formControl]="userEmail"
                  placeholder="Your email address"
                /><input
                  type="submit"
                  value="Subscribe"
                  (click)="subscribeEmail()"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div
            class="btm_bar d-flex align-items-center justify-content-between"
          >
            <p class="copyright">
              <span>©</span> Copyright 2024. Powered by
              <a
                href="https://vizzwebsolutions.com"
                target="_blank"
                rel="noopener noreferrer"
                >Vizzwebsolutions.com</a
              >
            </p>
            <div class="credits">
              <img
                src="../../../../assets/images/atm-card.svg"
                alt="ATM Cards Image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      class="flowers_bg flowers_bg2"
      src="../../../assets/images/footerbg.webp"
      alt=""
    />
  </footer>
  <img
    class="flowers_bg"
    src="../../../assets/images/newfooterbg.webp"
    alt="Flowers"
  />
  <div class="sideblur"></div>
</section>
