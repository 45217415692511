import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

import { HeaderComponent } from 'src/app/shared/header/header.component';
import { FooterHomeComponent } from 'src/app/shared/footer-home/footer-home.component';
import { ServiceSectionComponent } from 'src/app/shared/service-section/service-section.component';
import { CheckOutComponent } from '../../main/check-out/check-out.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SeoService } from 'src/app/services/seo.services';

import { SharedServiceService } from 'src/app/services/shared-service.service';
import { HallBookingComponent } from 'src/app/main/booking/hall-booking/hall-booking.component';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ServiceSectionComponent,
    HallBookingComponent,
    CheckOutComponent,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterHomeComponent,
  ],
})
export class PageNotFoundComponent {
  locationName!: string;
  locationError: string | undefined;
  locationArray: any;
  locationAvailable: boolean = false;
  isLoggedIn: boolean = false;
  userEmail: FormControl;
  constructor(
    private http: HttpClient,
    private sharedService: SharedServiceService,
    private router: Router,
    private loaderService: NgxUiLoaderService,
    private meta: Meta,
    private seoService: SeoService
  ) {
    this.seoService.get_seo_data('home');
    this.userEmail = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
  }

  ngOnInit(): void {
    this.locationArray = JSON.parse(localStorage.getItem('geolocation') || '');
    if (this.locationArray) {
      this.locationAvailable = true;
      this.locationName = this.locationArray.location;
      console.log('location', this.locationName);
    }
  }

  shareData(data: any) {
    this.sharedService.selected_company = data;
  }

  detectLocation() {
    console.log('thi', this.locationAvailable);
    if (this.locationAvailable) {
      // this.toastr.info("Location Already Detected", "Info");
      return;
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.reverseGeocode(
            position.coords.latitude,
            position.coords.longitude
          );
        });
      } else {
        alert('Geolocation is not supported by this browser.');
      }
    }
  }

  reverseGeocode(latitude: number, longitude: number) {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;

    this.http.get(url).subscribe((data: any) => {
      if (data.display_name) {
        this.locationName = data.display_name;
        let dt = {
          latitude: latitude,
          longitude: longitude,
          location: this.locationName,
        };
        localStorage.setItem('geolocation', JSON.stringify(dt));
        // this.toastr.success("Found the Location", "Successfully");
      } else {
        this.locationName = 'Location not found';
        // this.toastr.error("Location not found", "Error");
      }
    });
  }

  selectBookHall() {
    this.router.navigate(['/halls'], {
      queryParams: { type: 'hall booking' },
    });
  }

  subscribeEmail() {
    console.log('subscribe');
    // if (this.userEmail.invalid) {
    //   this.toastr.error("Please enter a valid email", "Error");
    //   return;
    // } else if (!this.userEmail.value) {
    //   this.toastr.error("Email is required", "Error");
    // } else {
    //   this.toastr.success(
    //     `${this.userEmail.value} has successfully subscribed.`,
    //     "Subscribed"
    //   );
    // }
    // this.userEmail.reset();
  }
}
