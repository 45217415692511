import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class IpService {
    private apiUrl = 'https://ipinfo.io/110.93.240.111?token=006a8b7f29329e';
    // private apiUrl = 'https://ipinfo.io/json';
    constructor(private http: HttpClient) { }

    getIpAddress(): Observable<any> {
        return this.http.get(this.apiUrl);
    }
}
