import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { DataSharingService } from 'src/app/services/data-sharing-servcie';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { CategoryService } from '../../../app/services/category-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule],
})
export class HeaderComponent {
  loggedIn: boolean = false;
  constructor(
    private router: Router,
    private dss: DataSharingService,
    private authService: AuthServiceService,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedServiceService,
    private categoryService: CategoryService
  ) {
    this.sharedService.getLoginInStatus().subscribe((checked: Boolean) => {
      this.loggedIn = Boolean(checked);
    });
  }

  ngOnInit() {
    this.dss.onSignal().subscribe((value: any) => {
      if (value && value.type === 'login') {
        this.loggedIn = this.authService.isLoggedIn();
      }
      if (value && value.type === 'login-out') {
        this.loggedIn = false;
      }
    });

    this.loggedIn = this.authService.isLoggedIn();
  }

  selectCategory(categoryKey: string): void {
    this.categoryService.setCategoryKey(categoryKey);
  }

  shareData(data: any) {
    this.sharedService.selected_company = data;
  }

  closeDropdown() {
    const navbarToggler = document.getElementById('navbar-toggler-icon');
    const navbarCollapse = document.getElementById('navbarSupportedContent');

    if (navbarToggler && navbarCollapse) {
      const isNavbarOpen = navbarCollapse.classList.contains('show');
      if (isNavbarOpen) {
        (navbarToggler as HTMLElement).click();
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const navbarCollapse = document.getElementById('navbarSupportedContent');
    const navbarToggler = document.getElementById('navbar-toggler-icon');

    if (navbarCollapse && navbarToggler) {
      const isNavbarOpen = navbarCollapse.classList.contains('show');
      const clickedInsideNavbar =
        navbarCollapse.contains(target) || navbarToggler.contains(target);

      if (isNavbarOpen && !clickedInsideNavbar) {
        (navbarToggler as HTMLElement).click();
      }
    }
  }

  logout() {
    if (this.loggedIn) {
      this.authService.logout();
      this.loggedIn = false;
      this.loginPath();
    } else {
      this.loginPath();
      // this.router.navigate(["/"]);
    }
  }

  selectCustomerProfile() {
    this.closeDropdown();
    this.router.navigate(['/select-profile']);
  }

  selectBookHall() {
    this.closeDropdown();
    this.router.navigate(['/halls'], {
      queryParams: { type: 'hall booking' },
    });
  }

  selectCatering() {
    this.closeDropdown();
    this.router.navigate(['/vendor'], {
      queryParams: { type: 'catering' },
    });
  }

  selectPhotographer() {
    this.closeDropdown();
    this.router.navigate(['/vendor'], {
      queryParams: { type: 'photographer' },
    });
  }

  selectMakeUpArtist() {
    this.closeDropdown();
    this.router.navigate(['/vendor'], {
      queryParams: { type: 'makeup artist' },
    });
  }

  selectDecor() {
    this.closeDropdown();
    this.router.navigate(['/vendor'], {
      queryParams: { type: 'decor' },
    });
  }

  selectMusician() {
    this.closeDropdown();
    this.router.navigate(['/vendor'], {
      queryParams: { type: 'musician' },
    });
  }

  selectMehndiArtist() {
    this.closeDropdown();
    this.router.navigate(['/vendor'], {
      queryParams: { type: 'mehndi artist' },
    });
  }

  signUpCust() {
    this.closeDropdown();
    this.router.navigate(['/login']);
    this.dss.sendSignal({ type: 'signUp' });
  }

  loginPath() {
    this.closeDropdown();
    this.router.navigate(['/login']);
    this.dss.sendSignal({ type: 'logIn' });
  }
}
