import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { IpService } from 'src/app/services/ip.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
  standalone: true,
  imports: [CommonModule
  ],
})
export class AccessDeniedComponent implements OnInit, OnDestroy {
  // allowedIps: string[] = ["'123.45.67.89', '98.76.54.32'"]; // Add your allowed IPs here
  allowedIps: string[] = ["110.93.240.111", "154.192.1.82", "182.180.59.120", "203.99.181.17"]; // Add your allowed IPs here
  ipCheckSubscription: Subscription | any;

  constructor(private ipService: IpService, private router: Router) {

  }

  ngOnInit() {
    this.ipService.getIpAddress().pipe(first()).subscribe((res: any) => {
      let clientIp = res.ip;
      console.log(clientIp)
      if (this.allowedIps.includes(clientIp)) {
        this.router.navigate(['/']);
      }
    })
    // this.checkIp()
  }

  checkIp() {
    this.ipCheckSubscription = interval(5000) // Check every 5 seconds
      .pipe(
        switchMap(() => this.ipService.getIpAddress())
      )
      .subscribe(response => {
        const clientIp = response.ip;
        if (this.allowedIps.includes(clientIp)) {
          this.router.navigate(['/']);
        }
      });
  }
  ngOnDestroy() {
    if (this.ipCheckSubscription) {
      this.ipCheckSubscription.unsubscribe();
    }
  }
}
