import { Injectable, Inject } from '@angular/core';
// import { Api } from '../configs/Api';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class SeoService {
  //   public url=Api.BaseCommonApiUrl+'get_content'
  //   public base_url=Api.BaseCommonApiUrl
  constructor(
    @Inject(DOCUMENT) private dom: any,
    private http: HttpClient,
    private meta: Meta,
    private title: Title
  ) {}
  hall_title: any;
  type: any;
  location: any;
  pages = [
    {
      id: 'home',
      page_title: 'Home',
      title: 'Online Wedding Planning Platform in Pakistan - Hamara Venue',
      status: 'active',
      tags: [
        'wedding planner in Pakistan, best wedding planner in Pakistan, wedding planners in Pakistan Islamabad, wedding planner in Pakistan lahore, event planner in Pakistan, top wedding planner in Pakistan Islamabad, best event planners in Pakistan',
      ],
      keyword: [
        'wedding venues in Pakistan, bridal makeup in Pakistan, wedding photographers in Pakistan, catering in Pakistan, best henna artists in Pakistan, decor in Pakistan',
      ],
      description:
        'Hamara Venue: Your one-stop online wedding planning platform for photographers, wedding venues, bridal makeup artists, catering, decor, and henna artists, all at your fingertips.',
    },
    {
      id: 'halls',
      page_title: 'halls',
      title: 'Best Wedding Venues in Pakistan | Hamara Venue - ',
      status: 'active',
      tags: ['wedding venues, wedding venues pakistan, wedding venues near me'],
      keyword: [
        'Wedding Venues in Pakistan,marquee in lahore,best marquee in islamabad,wedding marquee islamabad,wedding marquee in lahore,best wedding marquee in lahore,wedding marquee in rawalpindi,marriage marquee in lahore,marquee marriage hall lahore,wedding venues,wedding venues pakistan,wedding venues near me',
      ],
      description:
        'Get the best wedding venues, halls, marquees, in Islamabad, Lahore, and Karachi to host your wedding with Hamara Venue, view their prices and more.',
    },

    {
      id: 'vendor-photographer',
      page_title: 'Wedding Photographer',
      title: 'Best Photographer in Pakistan | Hamara Venue ',
      status: 'active',
      tags: [
        'wedding photography, pakistani wedding photography, wedding photography packages',
      ],
      keyword: [
        'top wedding photographers in lahore,best wedding photographer in faisalabad, top wedding photographers in pakistan,professional photographer in karachi,best wedding photographers in lahore,wedding photographers rawalpindi,wedding photography,pakistani wedding photography,wedding photography packages',
      ],
      description:
        'Find the best wedding photographers in Pakistan catered to all your needs. Get their packages all catered to your needs, all available on Hamara Venue.',
    },
    {
      id: 'vendor-catering',
      page_title: 'Wedding Catering ',
      title: 'Best Catering  in Pakistan | Hamara Venue ',
      status: 'active',
      tags: ['catering, catering services, catering services near me'],
      keyword: [
        'best catering in karachi,best catering in lahore,best caterers in karachi,best caterers in lahore,best catering in islamabad,best catering in rawalpindi,top caterers in karachi,best food catering in lahore,Food catering services in Pakistan,wedding catering, bbq catering near me,catering business,wedding catering packages,catering,catering services,catering services near me',
      ],
      description:
        'Find the best and delicious catering service in Pakistan and get wedding catering packages and discounts. All information is available on Hamara Venue',
    },
    {
      id: 'vendor-make-up-artist',
      page_title: 'Makeup Artists ',
      title: 'Best Makeup Artists  in Pakistan | Hamara Venue ',
      status: 'active',
      tags: [
        'pakistani makeup artists, makeup artists near me, makeup artists',
      ],
      keyword: [
        'makeup artist in pakistan,best makeup artist in karachi,makeup artist in lahore,best makeup artist in lahore,makeup artist in karachi,best bridal makeup in lahore,best bridal makeup in karachi,best makeup salons in lahore,best makeup artist in islamabad,makeup artist in islamabad,best bridal makeup in islamabad,pakistani makeup artists,makeup artists near me,makeup artists',
      ],
      description:
        'Find best bridal artist in pakistan within your budget on Hamara Venue. Get complete details of salon makeup artist, hair stylist, their prices and many more',
    },
    {
      id: 'vendor-mehndi-artist',
      page_title: 'Makeup Artists',
      title: 'Best Makeup Artists in Pakistan | Hamara Venue ',
      status: 'active',
      tags: ['mehndi artist, mehndi artist near me, best mehndi artist'],
      keyword: [
        'best mehndi artist in pakistan,best mehndi artist in lahore pakistan,best mehndi design,best mehndi artist in islamabad,popular henna artist in karachi,mehndi artist near memehndi artist,mehndi artist near me,best mehndi artist',
      ],
      description:
        'All the famous Henna Artists are availble on your finger tips with Hamara Venue in Pakistan. Now plan your wedding in ease and style.',
    },
    {
      id: 'vendor-decor',
      page_title: 'Decor Artists',
      title: 'Best Decor Artists in Pakistan | Hamara Venue ',
      status: 'active',
      tags: [
        'home decoration for wedding with flowers,autumn wedding decorations,wedding party decorations,haldi ceremony decoration at home,wedding shower decorations,destination wedding planner in pakistan,best wedding planner in Pakistan,wedding planner in Karachi,wedding planner in Lahore,wedding planners in Pakistan,wedding planner in Islamabad,destination wedding planner in Pakistan',
      ],
      keyword: [
        'home decoration for wedding with flowers,autumn wedding decorations,wedding party decorations,haldi ceremony decoration at home,wedding shower decorations,destination wedding planner in pakistan,best wedding planner in Pakistan,wedding planner in Karachi,wedding planner in Lahore,wedding planners in Pakistan,wedding planner in Islamabad,destination wedding planner in Pakistan',
      ],
      description:
        'Avail the best wedding reception decorations available in Rawalpindi, Islamabad and Lahore, and all over Pakistan on Hamara Venue.',
    },
    {
      id: 'vendor-musician',
      page_title: 'Decor Artists',
      title: 'Best Musicians in Pakistan | Hamara Venue ',
      status: 'active',
      tags: [
        'live music performers, Pakistani musicians, wedding bands, event singers, professional musicians in Pakistan, top singers in Karachi, Lahore, Islamabad, music for corporate events, Pakistani music bands, best live bands in Pakistan',
      ],
      keyword: [
        'live music performers, Pakistani musicians, wedding bands, event singers, professional musicians in Pakistan, top singers in Karachi, Lahore, Islamabad, music for corporate events, Pakistani music bands, best live bands in Pakistan',
      ],
      description:
        'Discover the top musicians in Pakistan for weddings, corporate events, and parties. Find the best live bands and singers in Karachi, Lahore, Islamabad, and beyond with Hamara Venue.',
    },
    {
      id: 'blog',
      page_title: ' Hamara Venue | Blogs',
      title: 'Blogs | Hamara Venue ',
      status: 'active',
      tags: [
        'wedding guide, pakistani wedding guide, wedding trends, latest wedding trends, latest saree trends for weddings, latest design trends in clothes for wedding, News about wedding',
      ],
      keyword: [
        'wedding guide, pakistani wedding guide, wedding trends, latest wedding trends, latest saree trends for weddings, latest design trends in clothes for wedding, News about wedding',
      ],
      description:
        'Aim to stay ahead with the latest wedding trends from around the world. Discover customs, fashion, and tips to create an unforgettable celebration for your unique love.',
    },
    {
      id: 'faq',
      page_title: 'Frequently Asked Questions  |  Hamara Venue',
      title: "FAQ's | Hamara Venue ",
      status: 'active',
      tags: ['faq , Frequently Asked Questions'],
      keyword: ['faq , Frequently Asked Questions'],
      description: 'Frequently Asked Questions',
    },
    {
      id: 'about-us',
      page_title: 'About Us | Hamara Venue',
      title: ' About Us | Hamara Venue ',
      status: 'active',
      tags: ['About Us'],
      keyword: ['About Us'],
      description:
        'Get to know about us, the brains and sweat behind Hamara Venue. Running the top wedding planning platform in Pakistan.',
    },
    {
      id: 'privacy-policy',
      page_title: 'Privacy Policy | Hamara Venue',
      title: 'Privacy Policy | Hamara Venue ',
      status: 'active',
      tags: ['Privacy Policy'],
      keyword: ['Privacy Policy'],
      description: 'Get to know about our Privacy Policy, Privacy Policy',
    },
    {
      id: 'terms-and-condition',
      page_title: 'Terms and Conditions | Hamara Venue',
      title: 'Terms and Conditions | Hamara Venue ',
      status: 'active',
      tags: ['Terms and Conditions'],
      keyword: ['Terms and Conditions'],
      description: 'Terms and Conditions',
    },

    {
      id: 'contact-us',
      page_title: 'Contact Us | Hamara Venue',
      title: 'Contact Us | Hamara Venue ',
      status: 'active',
      tags: ['Contact'],
      keyword: ['Contact'],
      description: 'Contact us',
    },
    {
      id: 'wedding-venues-hall-profile',
    },

    {
      id: 'vendor_photographer',
    },
    {
      id: 'vendor_catering',
    },
    {
      id: 'vendor_decor',
    },
    {
      id: 'vendor_mehndi',
    },
    {
      id: 'vendor_make_up',
    },
    {
      id: 'vendor_musician',
    },
    {
      id: 'wedding-venues-profile',
    },
  ];

  //   setPageSeo(page_title:string){
  //     const token = sessionStorage.getItem('Token');
  //     // console.log('here is the page title::',page_title);
  //   return this.http.get(this.url,{
  //     headers: {
  //       Authorization: `bearer ${token}`,
  //     }
  //    }
  //     ).subscribe(
  //          (
  //           (res:any)=>{
  //             if(res.status==440){
  //               // this.toast.error('Unauthorized','please login first');
  //               // console.log('token messing checkout res :::',res);
  //             }
  //             else{
  //               // console.log('here is data of privacy>>>>::',res);
  //               let result=res.data.map(
  //                 (obj:any)=>{
  //                 return obj[page_title];
  //               }
  //               );
  //               // console.log('here in now iam chekcout result>>>> ',result);
  //               if(result){

  //                 // debugger
  //                 this.meta.addTag(
  //                   {
  //                     // author:'Dawood Sherazi',
  //                     title:result[0].title,
  //                     description:result[0].description,
  //                     keywords:result[0].tags.split(',')
  //                     }
  //                      );
  //                   this.title.setTitle(result[0].title)
  //                   // this.meta.updateTag({ name: 'description', content: result[0].tags });
  //                   // this.meta.addTag({name:"tags","content":result[0].tags})
  //               }

  //             }

  //           })

  //       )
  //    }

  //   removePageSeo(page_title){
  //     this.meta.removeTag(page_title);
  //   }

  // get_seo_data(page_title: any) {
  // return this.http.get(this.base_url + "get_seo_content", { params: { page_title: page_title } }).subscribe((res) => {
  //     let canURL = this.dom.URL || 'https://elmonx.com/';
  //     let link: HTMLLinkElement = this.dom.createElement('link');
  //     link.setAttribute('rel', 'canonical');
  //     this.dom.head.appendChild(link)
  //         ;
  //     link.setAttribute('href', canURL);

  //     let data_ = res['data']
  //     if (data_) {
  //         this.title.setTitle(data_[0].name);
  //         this.meta.updateTag({
  //             name: "description",
  //             content: data_[0].description
  //         });
  //         this.meta.updateTag({
  //             name: "keywords",
  //             content: data_[0].tags
  //         });
  //         this.meta.updateTag({
  //             property: "og:title",
  //             content: data_[0].name
  //         })
  //         this.meta.updateTag({
  //             property: "twitter:title",
  //             content: data_[0].name
  //         })

  //     }
  //     // this.meta.addTags(res?.data.tags)
  // })
  // }

  // venue_template = {
  //     "id": "wedding-venues-hall-profile",
  //     "page_title": "Wedding Venues",
  //     "title": "Best Wedding Venues in {{location}} | Hamara Venue",
  //     "status": "active",
  //     "tags": ["{{hall_name}} {{location}}", "{{hall_name}} {{type}}", "{{hall_name}} {{type}} rates", "{{hall_name}} {{type}} packages", "{{hall_name}} {{type}} contact", "{{hall_name}} {{type}} reviews", "{{hall_name}} {{type}} location"],
  //     "keyword": ["{{hall_name}} {{location}}", "{{hall_name}} {{type}}", "{{hall_name}} {{type}} rates", "{{hall_name}} {{type}} packages", "{{hall_name}} {{type}} contact", "{{hall_name}} {{type}} reviews", "{{hall_name}} {{type}} location"],
  //     "description": "This lovely {{type}} in {{location}}, {{hall_name}} is the best place for you. Hamara Venue is happy to bring them! So enjoy the luxury and joy they have in store for you."
  // };

  get_seo_data(id: any) {
    let canURL = this.dom.URL;
    // let canURL = this.dom.URL || '';
    let link: HTMLLinkElement = this.dom.getElementById('canonical_url');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);

    let dt = this.filterArray(this.pages, id);
    console.log('=', dt);

    if (dt) {
      this.title.setTitle(dt[0].title);
      this.meta.updateTag({
        name: 'description',
        content: dt[0].description,
      });
      this.meta.updateTag({
        property : 'og:description',
        content: dt[0].description,
      })
      this.meta.updateTag({
        name: 'og:description',
        content: dt[0].description,
      });
      this.meta.updateTag({
        name: 'twitter:description',
        content: dt[0].description,
      });

      this.meta.updateTag({
        property: 'og:title',
        content: dt[0].title,
      });
      this.meta.updateTag({
        property: 'twitter:title',
        content: dt[0].page_title,
      });
      this.meta.updateTag({
        name: 'twitter:title',
        content: dt[0].page_title,
      });
      this.meta.updateTag({
        name: 'keywords',
        content: dt[0].keyword,
      });
      this.meta.updateTag({
        name: 'tags',
        content: dt[0].tags,
      });
      this.meta.updateTag({
        property: 'twitter:site_name',
        content: dt[0].page_title,
      });
    }
  }

  get_seo(id: any, name?: any, location?: any, type?: any) {

    return

    let canURL = this.dom.URL;
    // let canURL = this.dom.URL || '';
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', canURL);

    let dt = this.filterArray(this.pages, id);

    if (dt[0].id === 'vendor_photographer') {
      this.title.setTitle(name + `| Hamara Venue`);
      this.meta.updateTag({
        name: 'description',
        content:
          name +
          ' is your go-to destination for heartwarming tales, diverse perspectives, and engaging content. Explore a world of stories that resonate with every soul. ',
      });
      this.meta.updateTag({
        name: 'og:description',
        content:
          name +
          ' is your go-to destination for heartwarming tales, diverse perspectives, and engaging content. Explore a world of stories that resonate with every soul. ',
      });
      this.meta.updateTag({
        name: 'twitter:description',
        content:
          name +
          ' is your go-to destination for heartwarming tales, diverse perspectives, and engaging content. Explore a world of stories that resonate with every soul. ',
      });
      this.meta.updateTag({
        property: 'og:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        property: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });

      this.meta.updateTag({
        name: 'keywords',
        content:
          name +
          '' +
          location +
          ', ' +
          name +
          ',' +
          name +
          'rates ,' +
          name +
          ' packages,' +
          name +
          'contact,' +
          name +
          ' reviews , ' +
          name +
          ' ' +
          location,
      });
      this.meta.updateTag({
        name: 'tags',
        content:
          name +
          '' +
          location +
          ', ' +
          name +
          ',' +
          name +
          'rates ,' +
          name +
          ' packages,' +
          name +
          'contact,' +
          name +
          ' reviews , ' +
          name +
          ' ' +
          location,
      });
      this.meta.updateTag({
        property: 'twitter:site_name',
        content:
          name +
          '' +
          location +
          ', ' +
          name +
          ',' +
          name +
          'rates ,' +
          name +
          ' packages,' +
          name +
          'contact,' +
          name +
          ' reviews , ' +
          name +
          ' ' +
          location,
      });
    } else if (dt[0].id === 'vendor_catering') {
      this.title.setTitle(name + `| Hamara Venue`);
      this.meta.updateTag({
        name: 'description',
        content:
          'Explore ' +
          name +
          '  delectable offerings and customizable wedding catering packages in' +
          location +
          '. Indulge in exquisite flavors at unbeatable rates. ',
      });
      this.meta.updateTag({
        name: 'og:description',
        content:
          'Explore ' +
          name +
          '  delectable offerings and customizable wedding catering packages in' +
          location +
          '. Indulge in exquisite flavors at unbeatable rates. ',
      });
      this.meta.updateTag({
        name: 'twitter:description',
        content:
          'Explore ' +
          name +
          '  delectable offerings and customizable wedding catering packages in' +
          location +
          '. Indulge in exquisite flavors at unbeatable rates. ',
      });

      this.meta.updateTag({
        property: 'og:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        property: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'keywords',
        content:
          '' +
          name +
          ', ' +
          name +
          ' ' +
          location +
          ', ' +
          name +
          ' ' +
          location +
          ' menu, wedding catering packages with prices ' +
          location +
          ', ' +
          name +
          ' packages rates, ' +
          name +
          ' catering prices',
      });
      this.meta.updateTag({
        name: 'tags',
        content:
          '' +
          name +
          ', ' +
          name +
          ' ' +
          location +
          ', ' +
          name +
          ' ' +
          location +
          ' menu, wedding catering packages with prices ' +
          location +
          ', ' +
          name +
          ' packages rates, ' +
          name +
          ' catering prices',
      });
      this.meta.updateTag({
        property: 'twitter:site_name',
        content:
          '' +
          name +
          ', ' +
          name +
          ' ' +
          location +
          ', ' +
          name +
          ' ' +
          location +
          ' menu, wedding catering packages with prices ' +
          location +
          ', ' +
          name +
          ' packages rates, ' +
          name +
          ' catering prices',
      });
    } else if (dt[0].id === 'vendor_decor') {
      this.title.setTitle(name + `| Hamara Venue`);
      this.meta.updateTag({
        name: 'description',
        content:
          'Experience seamless event planning with ' +
          name +
          '. Let us take care of the logistics while you delve into the magic of your event. 🥂✨ ',
      });
      this.meta.updateTag({
        name: 'og:description',
        content:
          'Experience seamless event planning with ' +
          name +
          '. Let us take care of the logistics while you delve into the magic of your event. 🥂✨ ',
      });
      this.meta.updateTag({
        name: 'twitter:description',
        content:
          'Experience seamless event planning with ' +
          name +
          '. Let us take care of the logistics while you delve into the magic of your event. 🥂✨ ',
      });

      this.meta.updateTag({
        property: 'og:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        property: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'keywords',
        content:
          'event planner in ' +
          location +
          ', top 10 event planner in ' +
          location +
          ', best event planner in ' +
          location +
          ', birthday event planner in ' +
          location +
          ', birthday party event planner in ' +
          location +
          '',
      });
      this.meta.updateTag({
        name: 'tags',
        content:
          'event planner in ' +
          location +
          ', top 10 event planner in ' +
          location +
          ', best event planner in ' +
          location +
          ', birthday event planner in ' +
          location +
          ', birthday party event planner in ' +
          location +
          '',
      });
      this.meta.updateTag({
        property: 'twitter:site_name',
        content:
          'event planner in ' +
          location +
          ', top 10 event planner in ' +
          location +
          ', best event planner in ' +
          location +
          ', birthday event planner in ' +
          location +
          ', birthday party event planner in ' +
          location +
          '',
      });
    } else if (dt[0].id === 'vendor_mehndi') {
      console.log('i am here');
      this.title.setTitle(name + ` | Hamara Venue`);
      this.meta.updateTag({
        name: 'description',
        content:
          'This wonderful henna artist in ' +
          location +
          ', Organic Henna By Maria is the best place for you. Hamara Venue is happy to bring them! So enjoy the style and look they have for you.',
      });
      this.meta.updateTag({
        name: 'og:description',
        content:
          'This wonderful henna artist in ' +
          location +
          ', Organic Henna By Maria is the best place for you. Hamara Venue is happy to bring them! So enjoy the style and look they have for you.',
      });
      this.meta.updateTag({
        name: 'twitter:description',
        content:
          'This wonderful henna artist in ' +
          location +
          ', Organic Henna By Maria is the best place for you. Hamara Venue is happy to bring them! So enjoy the style and look they have for you.',
      });

      this.meta.updateTag({
        property: 'og:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        property: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'keywords',
        content:
          'Organic Henna by ' +
          name +
          ' ' +
          location +
          ', Organic Henna by ' +
          name +
          ', Organic Henna by ' +
          name +
          ' rates, Organic Henna by ' +
          name +
          ' packages, Organic Henna by ' +
          name +
          ' contact',
      });
      this.meta.updateTag({
        name: 'tags',
        content:
          'Organic Henna by ' +
          name +
          ' ' +
          location +
          ', Organic Henna by ' +
          name +
          ', Organic Henna by ' +
          name +
          ' rates, Organic Henna by ' +
          name +
          ' packages, Organic Henna by ' +
          name +
          ' contact',
      });
      this.meta.updateTag({
        property: 'twitter:site_name',
        content:
          'Organic Henna by ' +
          name +
          ' ' +
          location +
          ', Organic Henna by ' +
          name +
          ', Organic Henna by ' +
          name +
          ' rates, Organic Henna by ' +
          name +
          ' packages, Organic Henna by ' +
          name +
          ' contact',
      });
    } else if (dt[0].id === 'vendor_make_up') {
      this.title.setTitle(name + `| Hamara Venue`);
      this.meta.updateTag({
        name: 'description',
        content:
          ' ' +
          name +
          ' ' +
          ' Salon – Your go-to beauty salon in ' +
          ' ' +
          location +
          ' ' +
          '. Experience excellence at' +
          ' ' +
          name +
          ' ' +
          ' Beauty Salon. Book your appointment now for a stunning transformation.',
      });
      this.meta.updateTag({
        name: 'og:description',
        content:
          ' ' +
          name +
          ' ' +
          ' Salon – Your go-to beauty salon in ' +
          ' ' +
          location +
          ' ' +
          '. Experience excellence at' +
          ' ' +
          name +
          ' ' +
          ' Beauty Salon. Book your appointment now for a stunning transformation.',
      });
      this.meta.updateTag({
        name: 'twitter:description',
        content:
          ' ' +
          name +
          ' ' +
          ' Salon – Your go-to beauty salon in ' +
          ' ' +
          location +
          ' ' +
          '. Experience excellence at' +
          ' ' +
          name +
          ' ' +
          ' Beauty Salon. Book your appointment now for a stunning transformation.',
      });

      this.meta.updateTag({
        property: 'og:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        property: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'keywords',
        content:
          '' +
          name +
          ',' +
          name +
          ' ' +
          '  beauty parlor,' +
          ' ' +
          name +
          ' ' +
          '  beauty salon, ' +
          ' ' +
          name +
          ' ' +
          '  salon price list, best hair salon in ' +
          ' ' +
          location +
          ' ' +
          ' for ladies',
      });
      this.meta.updateTag({
        name: 'tags',
        content:
          '' +
          name +
          ' ' +
          ',' +
          name +
          ' ' +
          '  beauty parlor,' +
          ' ' +
          name +
          ' ' +
          '  beauty salon, ' +
          ' ' +
          name +
          ' ' +
          '  salon price list, best hair salon in ' +
          ' ' +
          location +
          ' ' +
          ' for ladies',
      });
      this.meta.updateTag({
        property: 'twitter:site_name',
        content:
          '' +
          name +
          ' ' +
          ',' +
          name +
          ' ' +
          '  beauty parlor,' +
          ' ' +
          name +
          ' ' +
          '  beauty salon, ' +
          ' ' +
          name +
          ' ' +
          '  salon price list, best hair salon in ' +
          ' ' +
          location +
          ' ' +
          ' for ladies',
      });
    } else if (dt[0].id === 'vendor_musician') {
      this.title.setTitle(name + `| Hamara Venue`);
      this.meta.updateTag({
        name: 'description',
        content:
          'Discover' +
          name +
          ' ' +
          ', a versatile musician in ' +
          ' ' +
          location +
          ' ' +
          ' offering live performances, music lessons, and professional recording services. Experience the best of ' +
          ' ' +
          location +
          ' ' +
          "'s music scene with " +
          ' ' +
          name +
          ' ' +
          '. Book now!',
      });
      this.meta.updateTag({
        name: 'og:description',
        content:
          'Discover' +
          name +
          ' ' +
          ', a versatile musician in ' +
          ' ' +
          location +
          ' ' +
          ' offering live performances, music lessons, and professional recording services. Experience the best of ' +
          ' ' +
          location +
          ' ' +
          "'s music scene with " +
          ' ' +
          name +
          ' ' +
          '. Book now!',
      });
      this.meta.updateTag({
        name: 'twitter:description',
        content:
          'Discover' +
          name +
          ' ' +
          ', a versatile musician in ' +
          ' ' +
          location +
          ' ' +
          ' offering live performances, music lessons, and professional recording services. Experience the best of ' +
          ' ' +
          location +
          ' ' +
          "'s music scene with " +
          ' ' +
          name +
          ' ' +
          '. Book now!',
      });

      this.meta.updateTag({
        property: 'og:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        property: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'keywords',
        content:
          '' +
          name +
          ' musician, ' +
          location +
          ' musician, live music ' +
          name +
          ', music lessons ' +
          name +
          ', music performances ' +
          name +
          ', musician for events, professional recording ' +
          name +
          ', original music ' +
          name +
          ', versatile musician, music entertainment ' +
          location +
          '',
      });
      this.meta.updateTag({
        name: 'tags',
        content:
          '' +
          name +
          ' musician, ' +
          location +
          ' musician, live music ' +
          name +
          ', music lessons ' +
          name +
          ', music performances ' +
          name +
          ', musician for events, professional recording ' +
          name +
          ', original music ' +
          name +
          ', versatile musician, music entertainment ' +
          location +
          '',
      });
      this.meta.updateTag({
        property: 'twitter:site_name',
        content:
          '' +
          name +
          ' musician, ' +
          location +
          ' musician, live music ' +
          name +
          ', music lessons ' +
          name +
          ', music performances ' +
          name +
          ', musician for events, professional recording ' +
          name +
          ', original music ' +
          name +
          ', versatile musician, music entertainment ' +
          location +
          '',
      });
    } else if (dt[0].id === 'wedding-venues-profile') {
      this.title.setTitle(
        name +
          ' ' +
          `Best Wedding Venues in` +
          ' ' +
          location +
          `| Hamara Venue`
      );
      this.meta.updateTag({
        name: 'description',
        content:
          'This lovely ' +
          ' ' +
          type +
          ' ' +
          'in' +
          ' ' +
          location +
          ',' +
          ' ' +
          name +
          ' is the best place for you.Hamara Venue is happy to bring them! So enjoy the luxury and joy they have in store for you.',
      });
      this.meta.updateTag({
        name: 'og:description',
        content:
          'This lovely ' +
          ' ' +
          type +
          ' ' +
          'in' +
          ' ' +
          location +
          ',' +
          ' ' +
          name +
          ' is the best place for you.Hamara Venue is happy to bring them! So enjoy the luxury and joy they have in store for you.',
      });
      this.meta.updateTag({
        name: 'twitter:description',
        content:
          'This lovely ' +
          ' ' +
          type +
          ' ' +
          'in' +
          ' ' +
          location +
          ',' +
          ' ' +
          name +
          ' is the best place for you.Hamara Venue is happy to bring them! So enjoy the luxury and joy they have in store for you.',
      });

      this.meta.updateTag({
        property: 'og:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        property: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'twitter:title',
        content: name + ` | Hamara Venue`,
      });
      this.meta.updateTag({
        name: 'keywords',
        content:
          ' ' +
          name +
          ' ' +
          ' ' +
          location +
          ' ' +
          ', ' +
          name +
          ' ' +
          ' ' +
          type +
          ' ' +
          ', ' +
          name +
          ' ' +
          ' ' +
          type +
          ' ' +
          ' ' +
          'rates , ' +
          ' ' +
          ' ' +
          name +
          ' ' +
          ' ' +
          ' ' +
          type +
          ' ' +
          ' ' +
          'packages, ' +
          name +
          ' ' +
          type +
          ' contact, ' +
          ' ' +
          name +
          ' ' +
          ' ' +
          type +
          ' reviews, ' +
          name +
          ' ' +
          ' ' +
          type +
          ' ' +
          ' location',
      });
      this.meta.updateTag({
        name: 'tags',
        content:
          ' ' +
          name +
          ' ' +
          ' ' +
          location +
          ' ' +
          ', ' +
          name +
          ' ' +
          ' ' +
          type +
          ' ' +
          ', ' +
          name +
          ' ' +
          ' ' +
          type +
          ' ' +
          ' ' +
          'rates , ' +
          ' ' +
          ' ' +
          name +
          ' ' +
          ' ' +
          ' ' +
          type +
          ' ' +
          ' ' +
          'packages, ' +
          name +
          ' ' +
          type +
          ' contact, ' +
          ' ' +
          name +
          ' ' +
          ' ' +
          type +
          ' reviews, ' +
          name +
          ' ' +
          ' ' +
          type +
          ' ' +
          ' location',
      });
      this.meta.updateTag({
        property: 'twitter:site_name',
        content:
          '' +
          name +
          ' ' +
          location +
          ' ' +
          ', ' +
          name +
          ' ' +
          '' +
          type +
          ' ' +
          ', ' +
          name +
          ' ' +
          ' ' +
          type +
          ' ' +
          '' +
          'rates , ' +
          ' ' +
          name +
          ' ' +
          '' +
          type +
          ' ' +
          'packages, ' +
          ' ' +
          name +
          ' ' +
          '' +
          type +
          ' ' +
          ' contact, ' +
          ' ' +
          name +
          ' ' +
          ' ' +
          type +
          ' ' +
          ' reviews, ' +
          ' ' +
          name +
          ' ' +
          ' ' +
          type +
          ' ' +
          ' location',
      });
    }
  }

  filterArray(array: any, id: any) {
    let data;
    if (!!id) {
      data = array.filter((item: any) => item.id === id);
    }
    return data;
  }
}
