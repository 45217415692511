import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    private categoryKey: string = '';

    setCategoryKey(key: string): void {
        this.categoryKey = key;
    }

    getCategoryKey(): string {
        return this.categoryKey;
    }
}
