<header>
  <section class="important_msg">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12 d-flex align-items-center">
          <marquee class="p-0 m-0">
            We are improving the website and app portal to make it more
            convenient for customers and business owners. The proper launch will
            be very soon.
          </marquee>
        </div>
      </div>
    </div>
  </section>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light bg-light bg-body-tertiary">
      <div class="container-fluid">
        <!-- Logo and Toggle button -->
        <a
          class="navbar-brand d-flex align-items-center gap-4"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLink="/"
        >
          <img
            class="logo"
            src="../../../assets/images/hamara-venue.webp"
            width="100%"
            height="100%"
            alt="Hamara Venue"
            title="Hamara Venue"
          />
        </a>
        <div class="d-flex">
          <div class="dropdown d-lg-none">
            <button
              class="btn"
              type="button"
              id="navbarDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                style="
                  border: 1px solid #fb6f10;
                  border-radius: 50px;
                  padding: 6px;
                "
                *ngIf="loggedIn"
                src="../../../assets/images/man.png"
                alt=""
                height="50"
                width="50"
              />
              <img
                *ngIf="!loggedIn"
                src="../../../assets/images/avatar.svg"
                alt="Avatar"
                class="avatar"
              />
            </button>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <ng-container *ngIf="!loggedIn">
                <li>
                  <a
                    class="dropdown-item"
                    routerLink="/login"
                    (click)="loginPath()"
                    >Login</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    routerLink="/sign-up"
                    (click)="signUpCust()"
                    >Sign-Up</a
                  >
                </li>
              </ng-container>
              <ng-container *ngIf="loggedIn">
                <li>
                  <a class="dropdown-item" routerLink="/user-profile"
                    >User Profile</a
                  >
                </li>
                <li><a class="dropdown-item" (click)="logout()">Log Out</a></li>
              </ng-container>
            </ul>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            id="navbar-toggler-icon"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a
                class="nav-link scrollto"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLink="/"
                (click)="closeDropdown()"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link scrollto"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLink="/about-us"
                (click)="closeDropdown()"
                >About</a
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Service <span></span>
              </a>
              <!-- <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" (click)="selectBookHall()">Book Hall</a></li>
                  <li><a class="dropdown-item" (click)="selectCatering()">Catering</a></li>
                  <li><a class="dropdown-item" (click)="selectMakeUpArtist()">Makeup Artist</a></li>
                  <li><a class="dropdown-item" (click)="selectPhotographer()">Photographer</a></li>
                  <li><a class="dropdown-item" (click)="selectDecor()">Decor</a></li>
                  <li><a class="dropdown-item" (click)="selectMusician()">Musician</a></li>
                  <li><a class="dropdown-item" (click)="selectMehndiArtist()">Mehandi</a></li>
              </ul> -->
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a
                    class="dropdown-item"
                    routerLink="/venues-and-marriage-halls"
                    (click)="shareData('venues-and-marriage-halls')"
                    (click)="closeDropdown()"
                    >Book Hall</a
                  >
                  <!-- <a class="dropdown-item" routerLink="/venues-and-marriage-halls"
                    [queryParams]="{ type: 'hall booking' }" (click)="closeDropdown()">Book Hall</a> -->
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    routerLink="/catering-services"
                    (click)="shareData('catering-services')"
                    (click)="closeDropdown()"
                    (click)="selectCategory('catering')"
                    >Catering</a
                  >
                  <!-- <a class="dropdown-item" routerLink="/vendor" [queryParams]="{ type: 'catering' }"
                    (click)="closeDropdown()">Catering</a> -->
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    routerLink="/makeup-artists"
                    (click)="shareData('makeup-artists')"
                    (click)="closeDropdown()"
                    (click)="selectCategory('makeup artist')"
                    >Makeup Artist</a
                  >
                  <!-- <a class="dropdown-item" routerLink="/vendor" [queryParams]="{ type: 'makeup artist' }"
                    (click)="closeDropdown()">Makeup Artist</a> -->
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    routerLink="/photography"
                    (click)="shareData('photography')"
                    (click)="closeDropdown()"
                    (click)="selectCategory('photographer')"
                    >Photographer</a
                  >
                  <!-- <a class="dropdown-item" routerLink="/vendor" [queryParams]="{ type: 'photographer' }"
                    (click)="closeDropdown()">Photographer</a> -->
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    routerLink="/decor"
                    (click)="shareData('decor')"
                    (click)="closeDropdown()"
                    >Decor</a
                  >
                  <!-- <a class="dropdown-item" routerLink="/vendor" [queryParams]="{ type: 'decor' }"
                    (click)="closeDropdown()">Decor</a> -->
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    routerLink="/musicians"
                    (click)="shareData('musicians')"
                    (click)="closeDropdown()"
                    >Musician</a
                  >
                  <!-- <a class="dropdown-item" routerLink="/vendor" [queryParams]="{ type: 'musician' }"
                    (click)="closeDropdown()">Musician</a> -->
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    routerLink="/mehndi-artists"
                    (click)="shareData('mehndi-artists')"
                    (click)="closeDropdown()"
                    >Mehndi Artist</a
                  >
                  <!-- <a class="dropdown-item" routerLink="/vendor" [queryParams]="{ type: 'mehndi artist' }"
                    (click)="closeDropdown()">Mehandi</a> -->
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a
                class="nav-link scrollto"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLink="/blog"
                (click)="closeDropdown()"
                >Blog</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link scrollto"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLink="/venue-companies"
                (click)="closeDropdown()"
                >Venue Companies</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link scrollto"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLink="/vendor-companies"
                (click)="closeDropdown()"
                >Vendors</a
              >
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link scrollto" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                routerLink="/pricing" (click)="closeDropdown()">Pricing</a>
            </li> -->
            <li class="nav-item">
              <a
                class="btn-business"
                routerLink="/select-profile"
                (click)="selectCustomerProfile()"
                >List your business</a
              >
              <!-- <a class="btn-business">List your business</a> -->
            </li>
            <!-- <li class="nav-item">
              <a class="btn-business" routerLink="/select-profile" (click)="closeDropdown()">List your business</a>
            </li> -->

            <li class="nav-item dropdown d-none d-lg-block">
              <a
                class="nav-link"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  style="
                    border: 1px solid #fb6f10;
                    border-radius: 50px;
                    padding: 6px;
                  "
                  *ngIf="loggedIn"
                  src="../../../assets/images/man.png"
                  alt=""
                  height="50"
                  width="50"
                />
                <img
                  *ngIf="!loggedIn"
                  src="../../../assets/images/avatar.svg"
                  alt="Avatar"
                  class="avatar"
                />
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown1">
                <ng-container *ngIf="!loggedIn">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/login"
                      (click)="loginPath()"
                      >Login</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/sign-up"
                      (click)="signUpCust()"
                      >Sign-Up</a
                    >
                  </li>
                </ng-container>
                <ng-container *ngIf="loggedIn">
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/user-profile"
                      (click)="shareData('user-profile')"
                      (click)="closeDropdown()"
                      >User Profile</a
                    >
                    <!-- <a class="dropdown-item" routerLink="/user-profile">User Profile</a> -->
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      routerLink="/log-out"
                      (click)="logout()"
                      >Log Out</a
                    >
                  </li>
                </ng-container>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>
