import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environment/environment";

@Injectable({
  providedIn: "root",
})
export class AuthServiceService {
  // private loginUrl = environment.baseURL + environment.loginApi;

  signIn = ["/login"];

  constructor(private router: Router, private http: HttpClient) { }

  isLoggedIn(): boolean {
    return localStorage.getItem("token") ? true : false;
  }

  logout(): void {
    localStorage.setItem('isLoggedIn', 'false')
    localStorage.removeItem("user");
    localStorage.removeItem("userData");
    localStorage.removeItem("user_token");
    localStorage.removeItem("token");

    this.router.navigate(this.signIn);
  }

  getLoggedInUserName(): string | null {
    sessionStorage.getItem("user");
    const userDataString = localStorage.getItem("user");
    return userDataString ? JSON.parse(userDataString) : null;
  }

  // userSignup(obj: any): Observable<any> {
  //   return this.http.post(environment.baseURL + environment.signupApi, obj);
  // }

  // userLoggedIn(obj: any): Observable<any> {
  //   return this.http.post(this.loginUrl, obj);
  // }

  // updateUserPassword(data: any): Observable<any> {
  //   return this.http.put(
  //     environment.apiBaseUrl + environment.updatePasswordApi,
  //     data
  //   );
  // }

  // getUserToken(): string | null {
  //   return localStorage.getItem("user_token");
  // }

  // getUserDetails(): Observable<any> {
  //   return this.http.get(
  //     environment.apiBaseUrl + "/api/vendor/profile/get_profile"
  //   );
  // }

  // updateUserDetails(data: any): Observable<any> {
  //   return this.http.put(
  //     `${environment.apiBaseUrl}/api/vendor/profile/update_profile`,
  //     data
  //   );
  // }
}
