import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class SharedServiceService {
  selected_company: any;
  blog_id: any = localStorage.getItem('blog_id')
    ? localStorage.getItem('blog_id')
    : '';

  all_halls: any = [];

  searchResults: any = null;

  constructor() {}
  public getLoginChecked = new BehaviorSubject<Boolean>(false);
  setLogedInStatus(status: Boolean) {
    this.getLoginChecked.next(status);
  }

  getLoginInStatus(): Observable<Boolean> {
    return this.getLoginChecked.asObservable();
  }
}
